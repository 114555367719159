import React, {useState} from "react";
import PropTypes from 'prop-types';
import DefaultTable from "../components/DefaultTable/DefaultTable";
import OrderForm from "../components/OrderForm/OrderForm";
import moment from 'moment';
import {Divider} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import ChangeStatus from "../components/OrderPage/ChangeStatus";
import OrderDetails from "../components/OrderPage/OrderDetails/OrderDetails";
import ClientOrders from "../components/OrderPage/ClientOrders";

const form = {
    name: 'order',
    title: 'Заказы',
    schema: [
        {
            type: 'string',
            name: 'serial',
            label: 'Серийный Номер',
        },
        {
            type: 'selectRemoteCreatable',
            name: 'type',
            label: 'Тип Устройства',
            api: '/deviceType/query',
            createAPI: '/deviceType/create',
            isArray: false,
            dataKeys: {value: '_id', label: 'name'}
        },
        {
            type: 'selectRemoteCreatable',
            name: 'brand',
            label: 'Брэнд Устройства',
            api: '/deviceBrand/query',
            createAPI: '/deviceBrand/create',
            isArray: false,
            dataKeys: {value: '_id', label: 'name'}
        },
        {
            type: 'selectRemoteCreatable',
            name: 'model',
            label: 'Модель Устройства',
            api: '/deviceModel/query',
            createAPI: '/deviceModel/create',
            isArray: false,
            dataKeys: {value: '_id', label: 'name'}
        },
    ],
    enrichment: {
        type: {
            label: "name",
            value: "_id"
        },
        brand: {
            label: "name",
            value: "_id"
        },
        model: {
            label: "name",
            value: "_id"
        }
    }
};

const columns = (fetchData) => {
    return [
        {
            dataIndex: 'orderNo', title: "№",
            sorter: true,
            width: 50
        },
        {
            dataIndex: 'createdAt', title: "Дата", sorter: true,
            render: value => <RenderDate date={value}/>,
            defaultSortOrder: 'ascend',
            width: 120
        },
        {
            shouldCellUpdate:  (record, prevRecord) => JSON.stringify(record.client) !== JSON.stringify(prevRecord.client),
            dataIndex: 'client', title: "Клиент",
            render: value => <ClientOrders client={value || {}}/>,
        },
        {
            dataIndex: 'device',
            title: "Устройство",
            shouldCellUpdate:  (record, prevRecord) => record.device !== prevRecord.device,
            render: value => value ?
                <span style={{whiteSpace: "nowrap"}}>{value.type.name}, {value.brand.name} {value.model.name}
                    <Divider style={{margin: 1}}/>
                    <Paragraph copyable onClick={e => e.stopPropagation()}>{value.serial}</Paragraph></span> :
                <span>Такого устройства нет</span>,
        },
        {
            dataIndex: "problem",
            title: "Неисправность"
        },
        {
            dataIndex: 'price',
            title: "Цена",
            sorter: true,
            render: value => <span
                style={{whiteSpace: "nowrap"}}>{value !== null && value !== undefined ? `${value} ₽` : ''} </span>,
        },
        {
            dataIndex: 'status',
            //shouldCellUpdate: (record, prevRecord) => record.status !== prevRecord.status,
            title: "Статус",
            render: (value, record) => (<ChangeStatus value={value} record={record} fetchData={fetchData}/>),
            filters: [
                {text: 'Новые', value: 'NEW'},
                {text: 'Продиагностирован', value: 'diagnosed'},
                {text: 'На согласовании', value: 'approval'},
                {text: "Требует внимание", value: 'needAttention'},
                {text: 'Ожидание З/Ч', value: 'waitForPieces'},
                {text: 'В работе', value: 'inProgress'},
                {text: 'Аутсорс', value: 'outsource'},
                {text: 'Готовые', value: 'done'},
                {text: 'Оплаченные', value: 'payed'},
            ],
        },
    ];
};

const RenderDate = React.memo(({date}) => {
    const currentDate = new Date();
    const createdDate = new Date(date);
    const diffTime = currentDate.getTime() - createdDate.getTime();
    const diff = diffTime / (1000 * 3600 * 24).toFixed(0);
    return (
        <span style={{color: diff > 3 ? 'red' : diff > 2 ? 'orange' : 'green', whiteSpace: "nowrap"}}>
            {moment(date).format('DD-MM-YYYY')}
        </span>
    )
});

const OrderPage = ({predefinedFilter, hideCreate, customColumns, onCreateCallback, predefinedSort}) => {
    const [openDetails, setOpenDetails] = useState(false);
    const [record, setRecord] = useState({});
    const onRowClick = (record) => {
        setOpenDetails(true);
        setRecord(record)
    };
    return (
        <>
            <DefaultTable
                schemaId={form.name}
                predefinedFilter={predefinedFilter}
                predefinedOrder={predefinedSort ? predefinedSort : {column: 'createdAt', direction: 'ascend'}}
                filterByOffice={true}
                drawerWidth={800}
                title={form.title}
                form={form}
                tableColumns={customColumns ? customColumns : columns}
                formRenderer={OrderForm}
                formWrapper={'Drawer'}
                onRowClick={onRowClick}
                hideCreate={hideCreate}
                onCreateCallback={onCreateCallback}

            />
            <OrderDetails
                open={openDetails}
                record={record}
                onClose={() => setOpenDetails(false)}
            />
        </>
    )
};

export default OrderPage;

OrderPage.propTypes = {
    predefinedFilter: PropTypes.object,
    hideCreate: PropTypes.bool,
    onCreateCallback: PropTypes.func
};

